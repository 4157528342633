/*chatcode*/

.BilangesForm {
    max-width: 70%;
    margin: auto;
  
  .formInput {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-shadow: none;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
  }
  
  .errorMessage,
  .errorMessageMail {
    color: red;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    display: block;
  }
  
  .container-contact100-form-btn {
    justify-content: center;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: -25px;
  }
  
  .contact100-form-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    margin-top: 20px;
    min-width: 160px;
    height: 50px;
    background-color: #bdc3c7;
    border-style: solid;
    border-radius: 25px;
    border-color: gray;
  
    font-family: 'Montserrat', sans-serif;
    font-size: 1.7rem;
    color: gray;
    line-height: 1.2;
  
    transition: all 0.4s;
  
    box-shadow: 0 10px 80px -12px #B5B812;
   
  }
  
  .contact100-form-btn i {
    margin-left: 8px;
    transition: all 0.3s;
  }
  
  .contact100-form-btn:hover {
    background-color: gray;
    color: #bdc3c7;
    box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
  }
  
  .contact100-form-btn:hover i {
    transform: translateX(10px);
  }
  
  
  .row {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  
  .row label {
    margin-bottom: 5px;
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  
  .row input {
    height: 30px;
    padding: 5px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  /*mon code*/
  
  p {
    margin-top: 3%;
    text-align: center;
  }

  .BilangesFormFlex{
    display: flex;
    margin-left: 2%;
    width: 98%;
    height: 80%;
  }
  
  .BilangesForm{
    width: 100%;
    height: 100%;
  }
  
  #contact-form{
    width: 100%;
    height: 100%;
  }
  
   .row{
    width: 99%;
    display: flex;
    flex-direction: row;
    margin-left: 2px;
    margin-top: 5px;
  }
  
  .row input[type=text] {
    width: 48%;
    color: white;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid gray;
    outline: none;
  }
  
 .row .errorMessage{
    width: 40%;
    font-size: 0.9rem;
    color: red;
    text-align: left;
  }
  
  .row .errorMessageMail{
    width:100%;
    font-size: 0.9rem;
    color: red;
    text-align: right;
  }
  
  .row input[type=email] {
    width: 48%;
    color: white;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid gray;
    margin-left: 4%;
  }
  
   .row input[name=subject] {
    width: 100%;
    color: white;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid gray;
  }
  
   .row textarea {
    width: 100%;
    height: 150px;
    box-shadow: none;
    color: white;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid gray;
  }
  
   .row input:focus {
    border-bottom: 2px solid #046D35;
  }
  
  .row textarea:focus {
    border-bottom: 2px solid #046D35;
  }

  & {
    background-color: #dcdde1;
  }
  
  p{
    color: green;
    font-weight: bold;
    margin-bottom: 3%;
  }

  ::placeholder{
    color: gray;
  }
  
}