.Container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  margin: 0;
  padding: 0;
  width: 0px;
  height: 0px;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
}

.Main {
  text-transform: uppercase;
}

.Container * {
  cursor: pointer;
  margin: 0 12px;
}


@media (max-width: 644px) {
  .Links {
    font-size: 0.7rem;
  }
}