/* src/components/CircularLoader.css */
.circular-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .progress-circle {
    margin: 4em;
    transform: rotate(0deg); /* Tourner pour que le cercle commence en haut */
  }
  
  .progress-background {
    fill: none;
    stroke: #f3f3f3; /* Couleur de fond */
    stroke-width: 10;
  }
  
  .progress-bar {
    fill: none;
    stroke: #B5B812; /* Couleur de la progression */
    stroke-width: 10;
    stroke-linecap: round; /* Arrondi les extrémités */
    transition: stroke-dashoffset 0.3s ease; /* Transition fluide */
  }
  
  .progress-text {
    font-size: 2rem;
    color: #333;
    margin-top: 20px;
  }

  /* Ajoutons une animation spin */
@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .spinner {
    fill: none;
    stroke: #B5B812; /* Couleur du trait rotatif */
    stroke-width: 1;
    stroke-linecap: round;
  }
  
  