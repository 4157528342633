.Container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.1)
}
h1 span{
  color: #046D35;
} 
h1{
  margin: 0 auto;
  padding: 0;
  text-transform: uppercase;
  width: 90%;
  text-align: right;
  font-size: 3.5rem;
  color: #B5B812
}

