.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body{
  background-color: #E8F4E1;
  overflow-x: hidden; /*pas de défilement lattéral*/
  overflow-y: auto;
  font-family: 'Montserrat', sans-serif;
  /*scroll-behavior: smooth;*/
  height: 100%;
}

.container-sm{
  display: flex;
  flex-direction: column;
  height: 100vh;
  scroll-snap-type: y mandatory; /* Scroll snap sur l'axe Y */
  overflow-y: scroll;
}

.section {
 /* Assurez-vous que chaque section prend au moins la hauteur de l'écran */
  height: auto;
  scroll-snap-align: start; /* Alignement des sections au début */
}


#expertises{
  justify-self: center;
  align-self: center;
  max-width: 1366px;
}

#realisations{
  padding-top: 5vh;
}


#clients{
  justify-self: center;
  align-self: center;
  width: 100%;
}


#clients img

/* design scrollbar */
::-webkit-scrollbar {
  width:8px;
}

::-webkit-scrollbar-track {
  visibility: hidden;
}

::-webkit-scrollbar-thumb {
  background: gray;
  width: 2px;
  border-radius: 4px;
}


h1{
  font-size: 32px;
  font-weight: 700;
}

h2{
  font-size: 32px;
  font-weight: 700;
}

p{
  font-size: 16px;
  font-weight: 400;
}

/* Header */

.logo {
  z-index: 10;
  margin-top: 0.5rem; /* Utilisation d'une unité relative pour les marges */
  margin-right: 2.5rem; /* Utilisation d'une unité relative */
  max-width: 40vw; /* La largeur est basée sur 40% de la largeur de la fenêtre */
  max-height: 10vw; /* La hauteur est basée sur 10% de la longeur de la fenêtre */
}


.sliderimage {
  float: right;
  max-width: 1vw; /* La largeur est basée sur 20% de la largeur de la fenêtre */
  height: auto; /* Conserve les proportions de l'image */
}

.navbar, .dropdown-menu{
background: transparent;
border: none;

}

/* Menu */

/* Agrandissement fluide du texte au survol */
.nav > li > a:focus, .nav > li > a:hover {
  text-decoration: none;
  background-color: transparent;
  color: #B5B812;
  font-size: 2rem; /* Augmenter la taille du texte au survol à 2rem */
  transition: font-size 0.9s ease; /* Transition fluide */
}

/* Taille normale du texte sans survol */
.nav > li > a {
  font-size: 1.5rem; /* Taille par défaut significativement plus grande */
  transition: font-size 0.9s ease; /* Transition pour le rétrécissement */
}

/* Style pour le menu */
.nav, .navbar-nav {
  font-weight: bold;
  left: 23%;
}

.navbar a, .dropdown-menu > li > a, 
.dropdown-menu > li > a:focus, .dropdown-menu > li > a:hover, 
.navbar-toggle {
  color: white;
}

/* Suppression des ombres sur les éléments du dropdown */
.dropdown-menu {
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* Bordure supérieure au survol des éléments du menu */
.nav li:hover:nth-child(8n+1), .nav li.active:nth-child(8n+1),
.nav li:hover:nth-child(8n+2), .nav li.active:nth-child(8n+2),
.nav li:hover:nth-child(8n+3), .nav li.active:nth-child(8n+3),
.nav li:hover:nth-child(8n+4), .nav li.active:nth-child(8n+4) {
  border-top: #282c34 3px solid;
}

/* Style pour les éléments de lien */
.linktir {
  box-sizing: border-box;
  width: 100%;
  padding: 80px 60px 60px 60px;
  min-height: 100vh;
  font-size: 2em;
  font-weight: 700;
}



/* Fixed facebook icon */

.socialmedia .icon-bar{
  z-index: 10;
  position: fixed;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.socialmedia .icon-bar:before{
  content: ""; 
  position: absolute; 
  left: 14px; 
  top: 14px; 
  border: solid 15px transparent;
  border-radius: 15px;
}

/* Style the icon bar links */
.socialmedia .icon-bar a {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s ease;
  color: gray;
  margin-left: 7px;
  font-size: 20px;
}

/* Style the social media icons with color, if you want */
.socialmedia .icon-bar a:hover {
  color: #bdc3c7;
}

.socialmedia .icon-bar:hover:before{
  border-color: gray;
}

  /* EXPERTISES */

.about{
  width: 90%;
  margin-top: 4%;
  text-align: center;
}

.expImage img{
  width: 190px; 
  height: 200px;
}

.expText h2{
  width: 180px;
  font-size:15px;
}

.expText div{
  width: 180px;
  text-align: left;
  font-size:12px;
}


/*fade in EXPERTISES*/

.element {
    transition: all 3s ease, -webkit-transform 3s ease;
}


@keyframes fadein {
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
/*fin fade in*/

/*.element img{
  border-left:solid 2px #046D35;
}*/


.element:nth-child(2) /* On prend le deuxième bloc élément */
{
  margin-top: 2%;
}

.element:nth-child(3)
{
  margin-top: 4%;
}

.element:nth-child(4)
{
  margin-top: 6%;
}

.element:nth-child(5)
{
  margin-top: 8%;
}

.important{
  color:#046D35;
  font-weight: bold;
}

/* Expertises liste à puces */

.element li {
  list-style: none;
  position: relative;
  padding: 0 0 0 20px;
  margin-bottom: 4px;
}

.element li:before{
  content: ""; 
  position: absolute; 
  left: 0; 
  top: 2px; 
  border: solid 8px #B5B812;
  border-radius: 8px;
}

.element li:after {
  content: ""; 
  position: absolute; 
  left: 6px;
  top: 6px;
  width: 3px;
  height: 6px;
  border: solid #046D35;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* "Réalisations" dans le fichier Carousel.scss */

  /* clients */
@media screen and (min-width: 500px){
  picture{
    display:flex;
    align-items: flex-end;
    min-height: 600px;
    min-width: 320px;
  }
  
  .clients{
    margin-top: 4.3%;
    height: 100%;
    width: 100%;
  }
}

  /* contact */

.contact{
  display: flex;
  flex-direction: column;
  width:100%;
	height:100%;
}

.contactTitle{
  padding-bottom: 3%;
  font-family: 'Montserrat', sans-serif;
  color: #ecf0f1;
}

.contactBody{
  display: flex;
	flex-direction: row; /* utiliser row-reverse pour inverser */
  flex-wrap: wrap;
	width:100%;
	height:100%;
}

.map{
  display: flex;
  margin-left: 2%;
  width: 48%;
}

.contactBodyright{
  width: 50%;
  display: flex;
  flex-direction: column;
}

.contactInfo{
  width: 100%;
  height: 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-size: 1.2rem;
}

/* contact formulaire */

.contactFormFlex{
  display: flex;
  margin-left: 2%;
  width: 98%;
  height: 80%;
}

.ContactForm{
  width: 100%;
  height: 100%;
}

#contact-form{
  width: 100%;
  height: 100%;
}

.ContactForm .row{
  width: 99%;
  display: flex;
  flex-direction: row;
  margin-left: 2px;
  margin-top: 5px;
}

.ContactForm .row input[type=text] {
  width: 48%;
  color: gray;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid gray;
  outline: none;
}

.ContactForm .row .errorMessage{
  width: 40%;
  font-size: 0.9rem;
  color: red;
  text-align: left;
}

.ContactForm .row .errorMessageMail{
  width:100%;
  font-size: 0.9rem;
  color: red;
  text-align: right;
}

.ContactForm .row input[type=email] {
  width: 48%;
  color: gray;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid gray;
  margin-left: 4%;
}

.ContactForm .row input[name=subject] {
  width: 100%;
  color: gray;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid gray;
}

.ContactForm .row textarea {
  width: 100%;
  height: 150px;
  box-shadow: none;
  color: gray;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid gray;
}

.ContactForm .row input:focus {
  border-bottom: 2px solid #046D35;
}

.ContactForm .row textarea:focus {
  border-bottom: 2px solid #046D35;
}


/* contact toast formulaire */

.submit-feedback{
  border-radius: 25px;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5rem;
  color: #046D35;
  background-color: #B5B812;
  width: 200px;
  height: 30px;
}

.submit-failure{
  border-radius: 25px;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5rem;
  color: #046D35;
  background-color: red;
  width: 200px;
  height: 30px;
}

.container-contact100-form-btn {
  justify-content: center;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: -25px;
}

.contact100-form-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  margin-top: 50px;
  min-width: 160px;
  height: 50px;
  background-color: #bdc3c7;
  border-style: solid;
  border-radius: 25px;
  border-color: gray;

  font-family: 'Montserrat', sans-serif;
  font-size: 1.7rem;
  color: gray;
  line-height: 1.2;

  transition: all 0.4s;

  box-shadow: 0 10px 80px -12px #B5B812;
 
}

.contact100-form-btn i {
  margin-left: 8px;
  transition: all 0.3s;
}

.contact100-form-btn:hover {
  background-color: gray;
  color: #bdc3c7;
  box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
}

.contact100-form-btn:hover i {
  transform: translateX(10px);
}

  /* Footer */

footer{
  margin-top: 2%;
}

.copyright{
  font-size: 1rem;
  text-align: center;
  background-color: #B5B812;
}

.copyright .incognito:nth-child(1) a{
  float: left;
  color: transparent;
  cursor: default;
}

.copyright .incognito:nth-child(3) a{
  float: right;
  color: transparent;
}


/* 404 */
.notfound{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}



/*
* Responsive Design
*/

.container-sm{
  overflow-x:hidden;
}

/* responsive burger menu */
.navbar-toggle .icon-bar{
  position: relative;
  color: transparent;
  background: #333;
}

/* Responsive Clients */

/* smartphone */
@media screen and (max-width: 550px) {

  /* Header */

  .container-sm{
    overflow-x:hidden;
  }

  .navbar a, .dropdown-menu > li > a, 
.dropdown-menu > li > a:focus, .dropdown-menu > li > a:hover, 
.navbar-toggle {
  color: #046D35;
}


  .logo{
    float: left;
    width: auto; /* La largeur est basée sur 10% de la largeur de la fenêtre */
    height: 40px; /* La hauteur est basée sur 10% de la largeur de la fenêtre */
  }

  .nav, .navbar-nav{
    left: 0%;
  }

  .container-fluid > .navbar-collapse, .container-fluid > .navbar-header, .container > .navbar-collapse, .container > .navbar-header
  {
    background-color: #E8F4E1;
    text-align: center;
  }

  /* Fixed facebook icon */

.socialmedia .icon-bar{
  z-index: 10000;
  position: fixed;
  top: 5%;
  left: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.socialmedia .icon-bar:before{
  content: ""; 
  position: absolute; 
  left: -9px; 
  top: -1px; 
  border: solid 15px transparent;
  border-radius: 15px;
}

/* Style the icon bar links */
.socialmedia .icon-bar a {
  display: block;
  text-align: center;
  padding: 0px;
  transition: all 0.3s ease;
  color: gray;
  margin-left: 0px;
  font-size: 20px;
}

  /* margin expertise */
  .exp{
    padding-top: 35px;
  }

  .about{
    max-width: 500px;
  }

  picture{
    display:flex;
    align-items: center;
  }
  
  .clients{
    margin-top: 4.3%;
    height: 100%;
    width: 1365px;
  }

 picture .clients{
  min-height: 500px;
  width: 100%;
 }

.contactTitle{
  margin-top: 7%;
  padding-bottom: 0px;
  font-family: 'Montserrat', sans-serif;
  color: #ecf0f1;
}

.map{
  display: flex;
  margin-left: 0%;
  width: 96%;
  max-height: 400px;
}

/* Contact */

#contact{
  min-height: 150vh;
  height : auto;
}

.contactBodyright{
  width: 96%;
  display: flex;
  flex-direction: column;
}

.contactInfo{
  width: 100%;
  height: 20%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  font-size: 1.3rem;
}

.contact100-form-btn {
  margin-bottom: 20px;
}

}

/* smartphone mode paysage */
@media screen and (max-height: 420px) {

    /* Header */

    .container-sm{
      overflow-x:hidden;
    }
  
    .logo{
      float: left;
    }
  
    .nav, .navbar-nav{
      left: 0%;
    }
  
    .container-fluid > .navbar-collapse, .container-fluid > .navbar-header, .container > .navbar-collapse, .container > .navbar-header
    {
      background-color: #bdc3c7;
      text-align: center;
    }

    /* margin expertise */
    .exp{
      padding-top: 30px;
    }

  .expImage img{
    width: 190px; 
    height: 120px;
  }
  
  .expText h2{
    width: 180px;
    font-size:12px;
  }
  
  .expText div{
    width: 180px;
    text-align: left;
    font-size:10px;
  }

  picture{
    display:flex;
    align-items: flex-start;
    min-height: 400px;
    min-width: 320px;
    max-width: 1366px;
  }

  picture .clients{
    margin-bottom: 25%;
    min-height: 300px;
    width: 100%;
   }

   .contactInfo{
    width: 100%;
    height: 15%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    font-size: 1.3rem;
  }
}

/* Responsive Contact */

@media screen and (min-width: 1200px) {
  /* règles CSS pour les écrans de 1200px de large ou plus */
  body {
    font-size: 20px;
    width: 100%;
  }
  
  #header {
    width: 80%;
  }
}