.Container {
  margin: 0 auto;
  padding: 0;
  text-transform: uppercase;
  width: 90%;
  text-align: right;
  color: #B5B812;
  font-weight: 700;
}
.Container span{
  color: #046D35;
  font-weight: 700;
}